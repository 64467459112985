<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <v-row class="infoWarp">
        <v-col cols="2" />
        <v-col cols="8" />
        <v-col cols="2">
          <div class="lblText lblTitle text-right">
            <v-select
              v-model="year"
              :items="yearList"
              item-value="value"
              item-text="name"
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <div class="lblTitle">
            <h3>{{ $t("messages.factoryName") }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-autocomplete
              v-model="customer"
              :items="customers"
              :item-text="
                (item) =>
                  item.code == null ? item.name : item.code + ' - ' + item.name
              "
              item-value="id"
              :no-data-text="$t('table.messages.no_data')"
              clearable
              single-line
              outlined
            />
          </div>
        </v-col>
        <v-col cols="5">
          <div class="lblTitle">
            <h3>{{ $t("messages.jobName") }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-select
              v-model="job"
              :items="jobs"
              item-text="name"
              item-value="id"
              outlined
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-btn
            large
            color="forth"
            class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-star"
            @click="openAddPlan"
          >
            <span class="font-weight-bold text-h5">
              {{ $t("messages.addNewPlan") }}</span>
          </v-btn>
        </v-col>
        <v-col>
          <v-row class="d-flex justify-end">
            <v-col cols="5">
              <div class="align-self-center">
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0"
                  :placeholder="$t('placeholder.planSearch')"
                  outlined
                  clearable
                  @keydown.enter="initialize"
                  @click:clear="initialize"
                />
              </div>
            </v-col>
            <v-col cols="2">
              <div class="mb-9">
                <v-btn
                  large
                  color="primary"
                  @click="initialize"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t("table.messages.search") }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="items"
            class="elevation-1"
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :items-per-page="10"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :no-data-text="$t('table.messages.no_data')"
          >
            <template
              v-slot:footer.page-text
              class="mr-0"
            >
              {{ $t("table.messages.page") }}
              {{ options.page }}
            </template>

            <template v-slot:item.index="props">
              {{ getIndex(props.index) }}
            </template>

            <template v-slot:item.manageId="props">
              {{ getValueObject(props.item, "parttimeEmployee.manageId") }}
            </template>

            <template v-slot:item.name="props">
              {{ getValueObject(props.item, "parttimeEmployee.user.name") }}
            </template>

            <template v-slot:item.factoryName="props">
              {{ getValueObject(props.item, "shift.job.customer.user.name") }}
            </template>
            <template v-slot:item.job="props">
              {{ getValueObject(props.item, "shift.job.name") }}
            </template>

            <template v-slot:item.scheduledTime="props">
              {{
                getShiftValue(
                  getValueObject(props.item, "startAt", "00:00"),
                  getValueObject(props.item, "endAt", "00:00")
                )
              }}
            </template>

            <template v-slot:item.shiftTime="props">
              {{
                getShiftValue(
                  getValueObject(props.item, "shift.shiftStartAt", "00:00"),
                  getValueObject(props.item, "shift.shiftEndAt", "00:00")
                )
              }}
            </template>

            <template v-slot:item.scheduledAllocated="props">
              {{ getValueObject(props.item, "plannedDate") }}
            </template>

            <template v-slot:item.dateEntered="props">
              {{ getDate(getValueObject(props.item, "createdAt")) }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-icon
                dark
                color="#C5DCFA"
                @click="deleteItem(item)"
              >
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-dialog
        v-model="dialog"
        max-width="800px"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <validation-observer
                ref="obValidate"
                v-slot="{ handleSubmit }"
              >
                <v-form
                  ref="form"
                  lazy-validation
                  @submit.prevent="handleSubmit(onSubmit)"
                >
                  <div class="borderDialog">
                    <v-row class="align-center">
                      <v-col cols="3">
                        <div class="lblText">
                          <h3>
                            {{ $t("messages.search_parttimer_list_text")
                            }}<span class="red--text"> *</span>
                          </h3>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="manageId"
                            :items="parttimerList"
                            :item-text="getNameParttime"
                            :item-disabled="item => disableParttimer(item)"
                            item-value="id"
                            dense
                            :no-data-text="$t('table.messages.no_data')"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="align-center">
                      <v-col cols="3">
                        <div class="lblText">
                          <h3>
                            {{ $t("messages.factoryName") }}
                            <span class="red--text"> *</span>
                          </h3>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="customerId"
                            :items="customerListNotFixed"
                            :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
                            item-value="id"
                            dense
                            :no-data-text="$t('table.messages.no_data')"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="align-center">
                      <v-col cols="3">
                        <div class="lblText">
                          <h3>
                            {{ $t("messages.job") }}
                            <span class="red--text"> *</span>
                          </h3>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="jobId"
                            :items="jobList"
                            item-text="name"
                            item-value="id"
                            dense
                            :no-data-text="$t('table.messages.no_data')"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="align-center">
                      <v-col cols="3">
                        <div class="lblText">
                          <h3>
                            {{ $t("messages.shiftTime") }}
                            <span class="red--text"> *</span>
                          </h3>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="shiftId"
                            :items="shiftList"
                            :item-text="getNameShift"
                            item-value="id"
                            dense
                            :no-data-text="$t('table.messages.no_data')"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="align-center">
                      <v-col cols="3">
                        <div class="lblText">
                          <h3>
                            {{ $t("messages.scheduledTime") }}
                            <span class="red--text"> *</span>
                          </h3>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <v-row>
                          <v-col cols="6">
                            <input-time-picker
                              :value="planStartAt"
                              :rules="'required|checktime|check48h'"
                              :plan="'start'"
                              @save="saveTime"
                            />
                          </v-col>
                          <v-col cols="6">
                            <input-time-picker
                              :value="planEndAt"
                              :rules="'required|checktime|check48h'"
                              :plan="'end'"
                              @save="saveTime"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" />
                      <v-col cols="9">
                        <v-checkbox v-model="checkboxTimeShift">
                          <template v-slot:label>
                            <div>
                              {{ $t("messages.similarToShiftTime") }}
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="borderDialog">
                    <v-row class="align-center">
                      <v-col cols="3">
                        <div class="lblText">
                          <h3>{{ $t("table.headers.scheduledAllocatedPlan") }}</h3>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="dates"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <v-combobox
                                v-model="dates"
                                multiple
                                chips
                                small-chips
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                :error-messages="errors[0]"
                                v-on="on"
                              />
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="dates"
                            multiple
                            no-title
                            scrollable
                            :min="nowDay"
                            locale="ja"
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(dates)"
                            >
                              {{ $t("messages.ok") }}
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                            >
                              {{ $t("messages.cancel") }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-5 text-center">
                    <v-row>
                      <v-col cols="4" />
                      <v-col cols="4">
                        <v-btn
                          :color="'#25ACD8'"
                          rounded
                          dark
                          block
                          type="submit"
                        >
                          {{ $t("messages.register") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="4" />
                    </v-row>
                  </div>
                </v-form>
              </validation-observer>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogMsg"
        max-width="800px"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <div>
                    <div class="text-center">
                      <v-icon
                        v-if="
                          planStatus.worktimePerWeekOverflow &&
                            planStatus.planTime &&
                            planStatus.unvaliableTime
                        "
                        :color="'#1BC4BD'"
                        class="iconSuccess__lange"
                      >
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                      <v-icon
                        v-else
                        :color="'#C81E1E'"
                        class="iconSuccess__lange"
                      >
                        mdi-close-circle-outline
                      </v-icon>
                    </div>
                    <div
                      v-if="
                        planStatus.worktimePerWeekOverflow &&
                          planStatus.planTime &&
                          planStatus.unvaliableTime
                      "
                      class="text-center font-weight-bold text-h4"
                    >
                      登録に成功しました
                    </div>
                    <div
                      v-else
                      class="text-center font-weight-bold text-h4"
                    >
                      登録に失敗しました
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="borderDialog ma-0">
                    <v-row class="align-center ma-4">
                      <v-col
                        cols="4"
                        class="text-center"
                      >
                        <h4>
                          <v-icon
                            v-if="planStatus.unvaliableTime"
                            :color="'#1BC4BD'"
                          >
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          <v-icon
                            v-else
                            :color="'#C81E1E'"
                          >
                            mdi-close-circle-outline
                          </v-icon>
                          <span> アルバイトの可能時間 </span>
                        </h4>
                      </v-col>
                      <v-col
                        cols="4"
                        class="text-center"
                      >
                        <h4>
                          <v-icon
                            v-if="planStatus.worktimePerWeekOverflow"
                            :color="'#1BC4BD'"
                          >
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          <v-icon
                            v-else
                            :color="'#C81E1E'"
                          >
                            mdi-close-circle-outline
                          </v-icon>
                          <span> 28時間管理 </span>
                        </h4>
                      </v-col>
                      <v-col
                        cols="4"
                        class="text-center"
                      >
                        <h4>
                          <span v-if="planStatus.planTime">
                            <v-icon
                              :color="'#1BC4BD'"
                            >
                              mdi-checkbox-marked-circle-outline
                            </v-icon>
                            <span> 予定重ねる確認 </span>
                          </span>
                          <span v-else>
                            <v-icon
                              :color="'#C81E1E'"
                            >
                              mdi-close-circle-outline
                            </v-icon>
                            <span> 予定重ねる </span>
                          </span>
                        </h4>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="font-weight-bold text-h4">
            <v-row class="d-flex align-center justify-center">
              <v-col cols="1">
                <v-icon
                  large
                  color="blue darken-2"
                >
                  mdi-delete-outline
                </v-icon>
              </v-col>
              <v-col cols="8">
                この操作は元に戻りません。
                本当に削除してよろしいですか？
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions>
            <v-spacer />

            <v-btn
              class="text-h5"
              dark
              color="#E43E08"
              @click="deleteItemConfirm"
            >
              {{ $t('messages.delete') }}
            </v-btn>
            <v-btn
              class="text-h5"
              @click="close"
            >
              {{ $t('messages.cancel') }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'
  import InputTimePicker from '../../components/app/InputTimePicker'

  export default {
    name: 'Plan',
    components: {
      ValidationProvider,
      ValidationObserver,
      InputTimePicker,
    },
    data () {
      return {
        itemPaginate: [],
        items: [],
        search: '',
        showPickerStartAt: false,
        showPickerEndAt: false,
        showPickerStartAtEdit: false,
        showPickerEndAtEdit: false,
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '5%',
          },
          {
            text: this.$t('table.headers.manageId'),
            align: 'center',
            value: 'manageId',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.name'),
            align: 'start',
            value: 'name',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '15%',
          },
          {
            text: this.$t('table.headers.factoryName'),
            align: 'center',
            value: 'factoryName',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.job'),
            align: 'center',
            value: 'job',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.scheduledTime'),
            align: 'center',
            value: 'scheduledTime',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.shiftTime'),
            align: 'center',
            value: 'shiftTime',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.scheduledAllocatedPlan'),
            align: 'center',
            value: 'scheduledAllocated',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.dateEntered'),
            align: 'center',
            value: 'dateEntered',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: '',
            align: 'start',
            value: 'action',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '5%',
          },
        ],
        options: {},
        editMode: false,
        loading: false,
        editedItem: {
          id: '',
          name: '',
          startAt: '',
          endAt: '',
        },
        defaultItem: {
          id: '',
          name: '',
          startAt: '',
          endAt: '',
        },
        editedIndex: '',
        dialog: false,
        dialogDelete: false,
        snackbarMessage: '',
        snackbar: false,
        nowDay: moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
        year: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
        yearList: [],
        manageId: '',
        customerId: '',
        customerListNotFixed: [],
        shiftId: '',
        jobId: '',
        job: null,
        customer: null,
        dates: [],
        planStartAt: '',
        planEndAt: '',
        menu: false,
        menuPickerStartAt: false,
        menuPickerEndAt: false,
        checkboxTimeShift: false,
        dialogMsg: false,
        planStatus: {
          unvaliableTime: true,
          planTime: true,
          worktimePerWeekOverflow: true,
        },
        customers: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        jobs: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
      }
    },
    computed: {
      ...get('plan', ['message', 'status', 'error', 'list', 'errors']),
      parttimerList: get('parttimer@listNotFixed.parttimeEmployees'),
      customerList: get('customer@list.data.customers'),
      jobList: get('job@list'),
      shiftList: get('shift@shiftList.data.shifts'),
    },
    watch: {
      list (value) {
        this.$set(this, 'items', value)
      },
      year () {
        this.initialize()
      },
      job () {
        this.initialize()
      },
      customerId (value) {
        if (value) {
          this.$store.dispatch('job/getJobsCustomer', {
            id: value,
          })
        }
      },
      customerList (value) {
        const valueNotFixed = value.filter(el => !el.isFixed)
        value.forEach((item) => {
          this.customers.push(item)
        })
        this.$set(this, 'customerListNotFixed', valueNotFixed)
      },
      jobId (value) {
        if (value) {
          this.$store.dispatch('shift/getShift', value)
        }
      },
      jobList (value) {
        this.jobs = [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ]
        value.forEach((item) => {
          this.jobs.push(item)
        })
      },
      customer (value) {
        if (value) {
          this.$store.dispatch('job/getJobsCustomer', {
            id: value,
          })
        }

        if (!value) {
          this.jobs = [
            {
              id: null,
              name: this.$t('messages.all'),
            },
          ]
        }
        this.initialize()
        this.job = null
      },
      dialogMsg (value) {
        if (!value) {
          this.planStatus = {
            unvaliableTime: true,
            planTime: true,
            worktimePerWeekOverflow: true,
          }
        }
      },
      checkboxTimeShift (value) {
        if (value) {
          const shift = this.shiftList.find((item) => item.id === this.shiftId)
          if (shift) {
            this.planStartAt = _.get(shift, 'shiftStartAt', '00:00')
            this.planEndAt = _.get(shift, 'shiftEndAt', '00:00')
          }
        } else {
          this.planStartAt = ''
          this.planEndAt = ''
        }
      },
      message (value) {
        if (value === 'successPlan') {
          this.closeDialog()
          this.resetForm()
          this.dialogMsg = true
          this.initialize()
        }

        if (value === 'error') {
          this.closeDialog()
          this.resetForm()
          this.errors.forEach((item) => {
            if (item.msg === 'OVERLAPSE UNAVAILABLE TIME') {
              this.planStatus.unvaliableTime = false
            }
            if (item.msg === 'OVERLAPSE PLAN TIME') {
              this.planStatus.planTime = false
            }
            if (item.msg === 'WORKTIME PER WEEK OVERFLOW') {
              this.planStatus.worktimePerWeekOverflow = false
            }
          })

          this.dialogMsg = true
        }

        if (value === 'success') {
          this.snackbarMessage('messages.success')
          this.initialize()
        }
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
    created () {
      this.getParttimer()
      this.getCustomers()
      this.initialize()
    },
    mounted () {
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })

      const start = moment('2021-01-01').year()
      const yearNow = moment(new Date()).year()
      const monthNow = moment(new Date()).month()

      for (let year = start; year <= yearNow; year++) {
        for (let i = 0; i < 12; i++) {
          this.yearList.push({
            name: `${year}年${this.pad(i + 1)}月`,
            value: `${year}-${this.pad(i + 1)}-01`,
          })

          if (year === yearNow && i === monthNow) {
            break
          }
        }
      }
      this.yearList.reverse()
    },

    methods: {
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      getNameParttime (parttime) {
        return _.get(parttime, 'manageId') + ' - ' + _.get(parttime, 'name', '')
      },
      getParttimer () {
        this.$store.dispatch('parttimer/getParttimerNotFixed')
      },
      disableParttimer (item) {
        if (item.language === '日本' || item.langguage === '日本人') {
          if (!item.residenceCardValidDate) {
            return false
          }
        } else {
          if (!item.residenceCardValidDate) {
            return true
          } else {
            return moment(item.residenceCardValidDate).diff(moment(), 'days') <= 0 || item.isActive === 0
          }
        }
      },
      getCustomers () {
        this.$store.dispatch('customer/getCustomers')
      },
      pad (d) {
        return d < 10 ? '0' + d.toString() : d.toString()
      },
      initialize () {
        const tmpOptions = { ...this.options }

        if (this.job) {
          tmpOptions.jobId = this.job
        }

        if (this.customer) {
          tmpOptions.customerId = this.customer
        }

        if (this.search) {
          tmpOptions.keyword = this.search.trim()
        }

        if (this.year) {
          tmpOptions.timestamp = this.year
        }

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('plan/getPlan', tmpOptions)
      },
      signUpSubmit () {
        const payload = {
          collegeId: this.$route.params.id,
          name: this.vacationName,
          startAt: this.dateStart,
          endAt: this.dateEnd,
        }
        this.$store.dispatch('colleague/createHoliday', payload)
        setTimeout(() => {
          this.vacationName = ''
          this.dateStart = ''
          this.dateEnd = ''
          this.initialize()
        }, 1000)
      },
      openAddPlan () {
        this.dialog = true
      },
      closeDialog () {
        this.dialog = false
      },
      onSubmit: _.debounce(function () {
        const payload = {
          parttimeEmployeeId: this.manageId,
          shiftId: this.shiftId,
          dates: this.dates,
          planStartAt: this.planStartAt,
          planEndAt: this.planEndAt,
        }

        this.$store.dispatch('plan/create', payload)
      }, 500),
      resetForm () {
        this.manageId = ''
        this.customerId = ''
        this.jobId = ''
        this.shiftId = ''
        this.dates = []
        this.planStartAt = ''
        this.planEndAt = ''
        this.checkboxTimeShift = false
        this.$refs.obValidate.reset()
        if (this.customer) {
          this.$store.dispatch('job/getJobsCustomer', {
            id: this.customer,
          })
        }
      },
      editItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      getNameShift (shift) {
        if (_.get(shift, 'shiftStartAt', '') === '') {
          return '00:00 ~ 00:00'
        }
        return (
          _.get(shift, 'shiftStartAt', '00:00') +
          ' ~ ' +
          _.get(shift, 'shiftEndAt', '00:00')
        )
      },
      close () {
        this.dialog = false
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = ''
        })
      },
      getShiftValue (start, end) {
        return start + ' ~ ' + end
      },
      deleteItemConfirm () {
        this.$store.dispatch('plan/delete', {
          id: this.editedIndex,
        })
        this.close()
        setTimeout(() => {
          this.initialize()
        }, 1000)
      },
      onSearch () {
        this.initialize()
      },
      paginate (array, pageSize, pageNumber) {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      getDate (value) {
        return moment(value).format('YYYY-MM-DD')
      },
      saveTime (payload) {
        if (payload.plan === 'start') {
          this.planStartAt = payload.data
        }
        if (payload.plan === 'end') {
          this.planEndAt = payload.data
        }
      },
    },
  }
</script>

<style scoped lang="sass">
.border-right
  border-right: thin solid rgba(0, 0, 0, 0.12) !important

.shiftWarp
  margin-top: 15px
  padding: 0 50px

  .infoWarp
    padding-top: 20px

  .lblText
    margin-top: 10px

  .lblTitle
    color: #5CA6D2

.dateWarp
  display: flex
  justify-content: space-between

  .icon-fall
    font-size: 20px
    font-weight: bold
    margin-top: 10px

.titleFlex
  display: flex
  align-items: center

.btnFlex
  display: flex
  align-items: center

  button
    margin: 0px !important

.shiftAt
  margin-left: 0px !important
.v-dialog
  overflow-y: hidden!important
.lblText
  color: #25ACD8
.borderDialog
  margin-top: 24px
  border: 1px solid #25ACD8
  border-radius: 5px
  padding: 10px
.iconSuccess__lange
  font-size: 70px
</style>
